import React,{ useState, useContext, useEffect} from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { IUBENDA_PRIVACY } from "../../integrazioni/iubenda"
import { useToast } from "../../toast/useToast"
import { GlobalContext } from "../MenuContext"
import { ButtonStyles } from "../Button/ButtonStyles"

const Contact = ({title, object}) => {

  const appName = "FvgLab"
  const form_action = process.env.GATSBY_EMAIL_FORM_ACTION

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [mailObj, setMailObj] = useState((object !== null && object !== undefined) ? object : "")
  const [message, setMessage] = useState("")
  const [readPolicy, setReadPolicy] = useState(false)
  const [loading, setLoading] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const toast = useToast()
  const showToast = (text, error) => toast.open(text, error)

  const { iubendaToggle, iubendaContent } = useContext(GlobalContext)
  const [, setOpenIubendaPolicy] = iubendaToggle
  const [, setContentModelIubenda] = iubendaContent

  const input_style =
  `bg-black w-full font-bold font-sans mt-0 mb-9 px-4 py-6
  placeholder:text-zinc-600 outline outline-2 outline-zinc-600
  rounded-md text-sm md:text-base duration-500
  hover:outline-yellow-400 focus:outline-yellow-400`

  const clearState = () =>{
    setName("")
    setEmail("")
    setPhone("")
    setMessage("")
    setMailObj("")
    setReadPolicy(false)
  }

  const manageSendEmail = sended =>{
      if(sended){
          showToast("Email correttamente inviata!", false)
          clearState()
      }else
          showToast("Errore durante l'invio: si prega di riprovare o di contattarci telefonicamente ", true)
      setLoading(false)
  }


  function checkRequiredParams(request){
    if (Boolean(request.name) && Boolean(request.mailObj) && Boolean(request.email) && Boolean(request.message))
      return true
    else
      return false
  }


  async function manageSubmit(event){
    event.preventDefault()
    setLoading(true)
    let res = false
    try{
      let result = checkRequiredParams({name:name, mailObj:mailObj, email:email, message:message})
      if(!result){
        showToast("Impossibile procedere. verifica di aver compilato i campi Nome, Email, Oggetto Email e Messaggio", true)
        setLoading(false)
        return
      }

      const res_token = await executeRecaptcha('contact')
    
      result = await fetch(form_action,{
        method: "POST",
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'charset': 'utf-8',
          "Content-Type": "application/json",
          "Origin": process.env.GATSBY_EMAIL_ORIGIN,
          "User-Agent":appName,
          "Connection": "keep-alive",
          authorization: process.env.GATSBY_PUBLIC_MAILER_API_KEY
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          appName: appName,
          tokenRecaptcha: res_token,
          name: name,
          senderMail: email,
          receiverMail: process.env.GATSBY_PUBLIC_MAILER_RECEIVER,
          phone: phone,
          object: process.env.GATSBY_PUBLIC_MAILER_OBJECT,
          mailObj: mailObj,
          message: message
        })
      })

      let result_json = await result.json()

      if(result_json !== null && (result_json.code === 200)) res = true
    }catch(err){
      console.error(err)
    }
    manageSendEmail(res)
  }

useEffect(()=>{
  if(object !== mailObj) setMailObj(object)
},[object])

  return (
    <section id="contact" className={"mx-auto box-content px-10 xs:px-0 xs:max-w-xs md:max-w-3xl " + (!title && "pt-44")}>
      {
        title &&
        <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-10 md:pb-14 lg:pb-20 font-semibold">
          {title}
        </h1>
      }
      <form name="contact" className="pb-44"
      onSubmit={(e) => {
        manageSubmit(e)
      }}>
        
        <input className={input_style} placeholder="Nome..." type="text" name="name"
        onChange={e=>setName(e.target.value)} value={name} required />

        <input className={input_style} placeholder="Telefono..." type="tel" name="phone"
        onChange={e=>setPhone(e.target.value)} value={phone} />
        
        <input className={input_style} placeholder="Email..." type="email" name="email"
        onChange={e=>setEmail(e.target.value)} value={email} required />
        
        <input className={input_style} placeholder="Oggetto..." type="text" name="object"
        onChange={event => setMailObj(event.target.value)} value={mailObj} required />
        
        <textarea
        className={input_style + " md:!mb-12"}
        placeholder="Il tuo messaggio..."
        name="message" rows="5" value={message}
        onChange={e=>setMessage(e.target.value)}
        required />

        <div className="">

          <input type="checkbox" name="policy"
          onChange={e => {}}
          onClick={()=>{
            let tmp_read = readPolicy
            setReadPolicy(!tmp_read)
          }}
          checked={readPolicy}
          />

          <span className="pl-5 text-zinc-300 text-sm">
            <span>Ho letto </span>
            <a href="#iubenda"
            className="hover:text-cyan-500 text-white underline duration-300
            cursor-pointer "
            title="Privacy Policy "
            onClick={e=>{
              e.preventDefault()
              setContentModelIubenda(IUBENDA_PRIVACY)
              setOpenIubendaPolicy(true)
            }}>
                l'informativa sulla privacy 
            </a>
            <span> ai sensi del GDPR 679/2016 e autorizzo il trattamento dei miei dati personali</span>
          </span>
        </div>

        <ButtonStyles disabled={!readPolicy} type="submit"
        className={" text-sm md:text-base lg:text-lg flex justify-center items-center mt-5 "
        + (!readPolicy && "!text-zinc-600 !cursor-not-allowed after:!bg-transparent")}>
          {
            loading ?
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-yellow-400 dark:text-blue-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            :
            null
          }
          Invia il messaggio
        </ButtonStyles>
      </form>
    </section>
  )
}

export default Contact
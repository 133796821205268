export const Services = [
  // {
  // title: "Assistenza e Supporto informatico",
  // preview: "Garantiamo un attento e pronto servizio di assistenza all’utente (#bhelp desk#b) da #bremoto#b e non solo, fornendo un adeguato sostegno finalizzato ad un utilizzo ottimale degli strumenti informatici aziendali. Il nostro team offre un supporto completo a 360° e fornisce #bsoluzioni rapide ed efficaci#b per la risoluzione di qualsiasi problema tecnico di natura hardware e software e per mantenere aggiornata e protetta la rete informatica.",
  // description: "Garantiamo un attento e pronto servizio di assistenza all’utente (#bhelp desk#b) da #bremoto#b e non solo, fornendo un adeguato sostegno finalizzato ad un utilizzo ottimale degli strumenti informatici aziendali. Il nostro team offre un supporto completo a 360° e fornisce #bsoluzioni rapide ed efficaci#b per la risoluzione di qualsiasi problema tecnico di natura hardware e software e per mantenere aggiornata e protetta la rete informatica.",
  // img: "bg-[url('/images/support/header-1.jpg')]",
  // svg: "Information",
  // details:
  //     [
  //         {
  //             title:"Consulenza",
  //             description:`Quando si tratta di consulenza si cerca di individuare gli  esperti più qualificati nei loro campi. I consulenti mettono a disposizione del cliente la loro professionalità ed esperienza per il raggiungimento degli obiettivi di business ottimizzando l’utilizzo delle risorse.
  //             L’informatizzazione in un’azienda è sempre più determinante per potersi affermare ed essere competitiva con le altre realtà.
  //             Il consulente informatico individua cosa è indispensabile per l’operatività aziendale rendendo fluidi i processi lavorativi tramite la scelta delle migliori soluzioni applicabili e affiancandola nel processo di digitalizzazione.
  //             `,
  //             extra:"Techfriuli offre un'ampia gamma di servizi di consulenza IT per mettere la tecnologia al servizio delle competenze come strumento per il raggiungimento degli obiettivi di crescita e di miglioramento della produttività."
  //         },
  //         {
  //             title:"Gestione backup",
  //             description:`Il backup è sicuramente una delle operazioni fondamentali per tenere al sicuro i propri dati in caso di malfunzionamenti hardware, attacchi cyber ecc. Attraverso i dispositivi NAS e i software di backup è possibile configurare dei processi automatizzati per permettere la copia dei singoli dati o addirittura di intere macchine virtuali. Per una protezione aggiuntiva, in caso di disastri naturali, incendi, furti ecc. offriamo il servizio del backup in cloud.`,
  //         },
  //         {
  //             title:"Fornitura e configurazione Hardware",
  //             description:"Grazie alle partnership con i marchi più affermati siamo in grado di fornire i dispositivi hardware più in linea con le esigenze del cliente.",
  //         },
  //         {
  //             title:"Gestione server mail",
  //             description:"Gestiamo sia i server di posta locali (installati sui server aziendali) che quelli in cloud.",
  //         },
  //     ]
  // },
  // {
  // title: "Assistenza Sistemistica",
  // preview: "Forniamo una consulenza a #b360°#b che va dalla progettazione alla realizzazione e il collaudo dell’infrastruttura informatica e prosegue con l’evoluzione del sistema del cliente passo dopo passo. Forniamo server fisici e virtualizzati, client e dispositivi di rete. Gestiamo politiche di controllo sugli accessi e verifiche sulla #bsicurezza informatica#b.",
  // description: "Forniamo una consulenza a #b360°#b che va dalla progettazione alla realizzazione e il collaudo dell’infrastruttura informatica e prosegue con l’evoluzione del sistema del cliente passo dopo passo. Forniamo server fisici e virtualizzati, client e dispositivi di rete. Gestiamo politiche di controllo sugli accessi e verifiche sulla #bsicurezza informatica#b.",
  // img: "bg-[url('/images/support/header-1.jpg')]",
  // svg: "Hello",
  // details:
  //     [
  //         {
  //             title:"Creazione e gestione delle reti",
  //             description:"Techfriuli si occupa in primo luogo di fare un’attenta analisi delle esigenze espresse dal cliente per poi procedere con la progettazione e implementazione di reti cablate e wireless con un particolare riguardo alla sicurezza.",
  //         },
  //         {
  //             title:"Gestione server in cloud",
  //             description:`Forniamo soluzioni su misura per ospitare i vostri siti Web, gestionali e applicativi di varia natura su spazio in cloud sicuro ed efficiente.
  //             Inoltre gestiamo i record del DNS del dominio (per associare gli indirizzi IP a dei servizi collegati ad un dominio (es. server di posta, sito web, etc.).
  //             `,
  //         },
  //         {
  //             title:"Configurazione dispositivi di rete",
  //             description:"Configurazione di tutti i dispositivi necessari al funzionamento e alla sicurezza di una rete LAN o WLAN come Firewall, Switch, NAS, Access Point ecc. ",
  //         },
  //         {
  //             title:"Creazione e gestione dei server VPN",
  //             description:"Implementiamo il sistema di reti VPN aziendali che, attraverso un sistema di certificati, permettono una connessione crittografata e sicura alla rete locale e ai dispositivi di rete da remoto.",
  //         },
  //         {
  //             title:"Connettività e centralino VOIP in cloud e in locale",
  //             description:`Techfriuli è in grado di fornire, anche in questo campo, soluzioni specifiche e personalizzate avvalendosi della partnership di Promelit Communication System Srl, un Internet Service Provider che vanta 40 anni di storia nel mondo delle telecomunicazioni e che fornisce connettività e fonia VOIP in cloud.
  //             In alternativa offriamo la possibilità di avere anche centralini VOIP fisici completamente personalizzabili.
  //             Installiamo e configuriamo nei cellulari aziendali le applicazioni per ricevere ed effettuare chiamate dal numero fisso aziendale in qualunque momento, anche fuori ufficio.
  //             Offriamo inoltre un servizio di personlizzazione per i smartphone e tablet aziendali (configurazione delle mail aziendali, app centralino, VPN aziendale, etc.).`,
  //         },
  //         {
  //             title:"Configurazione e gestione server Microsoft e Linux",
  //             description:"Il server è considerato il cuore dell’infrastruttura informatica in un’azienda. Il nostro team specializzato è in grado di fornire le soluzioni adatte alle esigenze del cliente, gestendo e amministrando sia i server tradizionali Microsoft che i sistemi Linux. Questi ultimi permettono un notevole risparmio delle risorse fisiche del server.",
  //         },
  //         {
  //             title:"Gestione sistemi virtualizzazione",
  //             description:`Grazie alla virtualizzazione un server fisico può essere diviso in diverse macchine virtuali dove poter allocare i vari servizi, anche con diversi sistemi operativi, condividendo così le risorse del server principale. Questo processo permette ad un’azienda di utilizzare al massimo le risorse della sua infrastruttura IT.
  //             Uno dei principali vantaggi, oltre alla flessibilità e performance, è la possibilità di effettuare i backup di intere macchine virtuali garantendo il ripristino in pochissimo tempo nel momento del bisogno.
  //             Con la disponibilità di uno o più server fisici è possibile creare dei sistemi di ridondanza in tempo reale garantendo un failover istantaneo in caso di problemi di blocco del server principale.
  //             `,
  //         },
  //     ]
  // },
  {
    title: "Sviluppo Web",
    preview:
      "Realizziamo o personalizziamo Siti Web, Form di ricerca e/o inserimento dati, Landing Page e integrazioni con WordPress. Per i siti web forniamo servizi di monitoraggio, ottimizzazione SEO, configurazione di Google Analytics, Cookie e Privacy Solution in linea con il GDPR e integrazione con altri software. Offriamo quindi #bsoluzioni su misura#b, garantendo un risultato allineato alle aspettative e agli obiettivi del cliente.",
    description:
      "Realizziamo prodotti web oriented per ogni esigenza. Offriamo soluzioni personalizzabili, performanti, sicure, in linea con le ultime tecnologie e con le esigenze del cliente",
    img: "bg-[url('/images/support/header-1.jpg')]",
    svg: "Web_Builder",
    details: [
      {
        title: "Siti Web",
        description:
          "Creiamo siti web di qualunque tipo, con lo scopo di migliorare il business e soddisfare i bisogni del cliente. Realizziamo siti vetrina, aziendali ed e-commerce, dando molta importanza all'estetica e alla user experience.",
      },
      {
        title: "Landing Page",
        description:
          "Uno degli elementi più importanti nel Digital Marketing che ha come scopo quello di trasformare gli utenti in clienti.",
      },
      {
        title: "Monitoraggio",
        description:
          "Offriamo servizi di monitoraggio che consentano di avere il completo controllo delle propria infrastuttura IT, monitorando server e macchine virtuali. L'obiettivo è quello di poter garantire continuità ai propri servizi, venendo avvisati immediatamente nel caso in cui sorgano problemi per poterli risolvere quanto prima.",
      },
      {
        title: "SEO",
        description:
          "Servizio essenziale per ogni sito web. L'ottimizzazione SEO aumenta la visibilità del tuo sito migliorandone il posizionamento tra i risultati di un motore di ricerca.",
      },
      {
        title: "Google Analytics",
        description:
          "Configuriamo il tuo sito in modo che sia integrato a Google Analytics per poter monitorare tutti gli accessi e le visite al proprio sito tramite l'apposito pannello.",
      },
      {
        title: "Privacy & Cookie Solution",
        description:
          "Privacy e Cookie Policy sempre in linea con gli ultimi aggiornamenti del GDPR, per non incorrere in pesanti sanzioni.",
      },
    ],
  },
  {
    title: "Sviluppo Software",
    preview:
      "Il nostro team di full-stack developer si occupa di progettazione e sviluppo di Software SaaS (as a Service – Software come servizio in abbonamento) e cloud based. Realizziamo #bapplicazioni#b web #bpersonalizzabili#b e backend con la tecnologia che più si adatta all’esigenza, garantendo quindi prodotti e #bsistemi aggiornati, sicuri e affidabili.#b",
    description:
      "Il nostro team di full-stack developer si occupa di progettazione e sviluppo di Software SaaS (as a Service – Software come servizio in abbonamento) e cloud based. Realizziamo #bapplicazioni#b web #bpersonalizzabili#b e backend con la tecnologia che più si adatta all’esigenza, garantendo quindi prodotti e #bsistemi aggiornati, sicuri e affidabili.#b",
    img: "bg-[url('/images/support/header-1.jpg')]",
    svg: "Software_Engineer",
    details: [
      {
        title: "Sviluppo Software su commissione",
        description:
          "Progettiamo e sviluppiamo prodotti e componenti partendo dalle vostre esigenze, seguendo i requisiti definiti assieme.",
      },
      {
        title: "Progettazione e Sviluppo Full Stack",
        description:
          "Progettiamo e sviluppiamo Software web-oriented e cloud based, dal Backend al FrontEnd. Utilizziamo le ultime tecnologie per garantire prodotti sicuri, di facile utilizzo, performanti e scalabili sulle esigenze del cliente.",
      },
      {
        title: "Manutenzione & Sviluppo Evolutivo",
        description:
          "Possiamo aiutarti a mantenere software già esistenti, correggendo eventuali bug o implementando nuove funzionalità.",
      },
      {
        title: "Formazione sul Software",
        description:
          "Tramite manuali o corsi di formazione istruiamo gli operatori affinché utilizzino correttamente i software progettati assieme.",
      },
    ],
  },
  {
    title: "Sviluppo App Mobile",
    preview:
      "Sviluppiamo app per le principali piattaforme mobile, ovvero #biOS#b e #bAndroid#b. Le applicazioni custom verranno realizzate in linea con le policy dei sistemi operativi, garantendone l’uso e la corretta visualizzazione su ogni tipologia di device.",
    description:
      "Sviluppiamo app per le principali piattaforme mobile, ovvero #biOS#b e #bAndroid#b.",
    img: "bg-[url('/images/support/header-1.jpg')]",
    svg: "Web_Mobile",
    details: [
      {
        title: "Sviluppo a 360°",
        description:
          "Progettiamo e sviluppiamo applicazioni mobile personalizzate native, web e ibride, a seconda delle specifiche del progetto. I nostri prodotti sono compatibili con le principali piattaforme mobile, ovvero Android e iOS, su tutti i dispositivi.",
      },
      {
        title: "Manutenzione & Sviluppo Evolutivo",
        description:
          "Possiamo aiutarti a mantenere applicazioni mobile già esistenti, correggendo eventuali bug o implementando nuove funzionalità.",
      },
      {
        title: "Pubblicazione sugli Store",
        description:
          "Ci occupiamo anche della pubblicazione delle applicazioni negli Store di Apple e Google per poter permettere agli utenti di poter scaricare e usare liberamente l'app.",
      },
    ],
  },
  {
    title: "Code Refactoring & Analisi del Progetto",
    preview:
      "Valutiamo applicazioni o prodotti già esistenti per #brinnovarne#b il codice e garantire performance migliori e/o per poterlo poi #bpersonalizzare#b in base alle necessità del cliente. Analizziamo l’idea o la necessità del cliente e proponiamo l’architettura software più adatta.",
    description:
      "Il nostro team di sviluppatori e sistemisti può assisterti anche con prodotti già esistenti",
    img: "bg-[url('/images/support/header-1.jpg')]",
    svg: "Pair_Programming",
    details: [
      {
        title: "Code Refactoring",
        description:
          "Valutiamo applicazioni o prodotti già esistenti per rinnovarne il codice e garantire performance migliori.",
      },
      {
        title: "Analisi Progetto",
        description:
          "Analizziamo proposte o idee, verificandone la fattibilità tecnica e tecnologica, e proponiamo l’architettura software più adatta.",
      },
      {
        title: "Re-Ingegnerizzazione Processi",
        description:
          "Consolidiamo le soluzioni già esistenti o indirizziamo su alternative valide.",
      },
    ],
  },
]

import * as React from "react"

const SimpleBanner = ({ children, title, hideDot, back }) => {
  return (
    <section className="relative h-[70vh]">
      {back}
      {title && (
        <div className="absolute top-o bottom-0 left-0 flex items-end justify-start py-8 px-8 md:px-20">
          <div className="z-10 h-[20vh] w-full max-w-2xl md:w-[66vw]">
            <h1 className="inline-block border-b-2 border-slate-700 text-white
            font-bold text-4xl lg:text-5xl pb-2">
              {title}
              {
                !hideDot && <span className="text-yellow-500">.</span>
              }
            </h1>
          </div>
        </div>
      )}
      <div className="gradient"></div>
    </section>
  )
}

export default SimpleBanner
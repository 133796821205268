import * as React from "react"
// import { Link } from "gatsby"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
// import { Products } from "../constants/products"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import Contact from "../components/Contact/Contact"
import ServiceComponent from "../components/Post/LatestPosts"

const ServicesPage = () => {

  const [mailObject, setMailObject] = React.useState("")

  const children =           
    <StaticImage
    className="!absolute top-0 bottom-0 left-0 right-0 z-0 opacity-80"
    imgClassName="absolute top-0 bottom-0 left-0 right-0 z-0 "
    src="../../static/services.jpg"
    alt="Servizi" 
    placeholder="blurred"/>

  return (
    <>
      <Seo
      title="Servizi | FvgLab - servizi informatici personalizzati per aziende e privati"
      description={"Offriamo una vasta gamma di servizi informatici personalizzati per le esigenze di aziende e privati. Dallo sviluppo software alla sicurezza informatica, siamo qui per aiutarti a raggiungere i tuoi obiettivi digitali. Scopri di più sui nostri servizi e contattaci per una consulenza."}
      />
      <Layout>
        <SimpleBanner title="Come possiamo aiutarti?" hideDot back={children}>

        </SimpleBanner>
        
        <ServiceComponent
        title=""
        helper={setMailObject} />

        <div className="py-10 md:py-20" />

        <Contact title="Contattaci!" object={mailObject} />

      </Layout>
    </>
  )
}

export default ServicesPage

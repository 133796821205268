import React from "react"
import PostItem from "./PostItem"
import { Services } from "../../constants/services"

const LatestPosts = ({ title, introduction, helper }) => {
  return (
    <section className="relative pt-20 md:pt-40 px-9 md:px-20">
      <div className="mx-auto max-w-7xl">
        {title || introduction ? (
          <div className="mb-10 md:mb-20 max-w-full">
            {title && (
              <h2 className="md:text-center font-bold text-3xl md:text-4xl xl:text-5xl">
                {title}
                <span className="pl-1 text-yellow-400">?</span>
              </h2>
            )}
            {introduction && <p>{introduction}</p>}
          </div>
        ) : null}

        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-7 ">
          {Services.map((node, index) => {
            return <PostItem key={index} node={node} helper={helper} />
          })}
        </div>
        {/* <div className="learn__more">
          <Button text="All News Items" as={Link} to="/news" />
        </div> */}
      </div>
    </section>
  )
}

export default LatestPosts

import * as React from "react"
// import Button from "../Button/Button"
import { Link } from "gatsby"

const BlogItem = ({ ind, node, helper}) => {
  
  const { title, path, preview } = node

  const arr_description = preview?.split("#b")

  const htmlTitle =
    <h4 className="text-yellow-400 text-xl md:text-2xl lg:text-3xl py-2 md:py-3 lg:py-4">
      {title}
    </h4>
    
  const htmlDescription = 
    preview && 
      <p className="text-sm md:text-base text-slate-400 pt-5">
        {arr_description.map((string, index) =>{
          if(index % 2 !== 0)
            return <span key={index} className={"font-semibold text-slate-200 group-hover:text-yellow-400 duration-300"}>{string}</span>
          else
            return string
        })}
      </p>
      
  return (
    <div
    className={`group flex rounded-lg border-2 border-zinc-600 px-7 py-10 text-white
    overflow-hidden relative hover:border-yellow-500 shadow-lg hover:shadow-black hover:shadow-2xl
    justify-between duration-500 hover:bg-black/30`}>

      {
        helper 
        ?
        <Link key={ind} to={path || "#contact" } onClick={()=> helper(title)}>
          {htmlTitle}
          {htmlDescription}
        </Link>
        :
        <Link key={ind} to={path || "/contact" }>
          {htmlTitle}
          {htmlDescription}
        </Link>
      }

    </div>
  )
}

export default BlogItem
